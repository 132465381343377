<template>
  <div class="table">
    <template>
      <v-data-table
          :headers="headers"
          :items="formattedCallsData"
          :search="search"
          class="elevation-1"
          item-key="callsData.date"
          loading-text="Загрузка..."
          no-data-text="Данные отсутствуют"
          no-results-text="Ничего не найдено"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Список звонков</v-toolbar-title>
            <v-spacer></v-spacer>
            <!--            <v-switch-->
            <!--                v-model="singleExpand"-->
            <!--                label="Раскрыть только одну строку"-->
            <!--                class="mt-2"-->
            <!--            ></v-switch>-->
            <!--            <v-spacer></v-spacer>-->
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                hide-details
                label="Поиск..."
                single-line
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div style="display: flex">
            <v-icon
                class="mr-3"
                size="25"
                @click="editRowData(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                size="25"
                @click="deleteRow(item.id)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import request from "@/services/request";

export default {
  name: "CallDataTable",
  props: {
    callsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      calls: [],
      headers: [
        {text: 'Дата обращения', width: 140, value: 'date'},
        {text: 'Номер телефона', width: 160, value: 'phoneNumber'},
        {text: 'Тикет', value: 'hasTiketName'},
        {text: 'Оператор', value: 'operator.name'},
        {text: 'Страна', value: 'oksm.nameFull'},
        {text: 'Регион', value: 'region.name'},
        {text: 'Тема обращения', width: 250, value: 'applicationTopic.name'},
        {text: 'Статус', value: 'callStatus.name'},
        {text: 'Описание', value: 'content'},
        {text: 'Примечание', value: 'description'},
        {text: '', value: 'actions', sortable: false}
      ]
    }
  },
  mounted() {
    this.calls = [...this.callsData]
  },
  watch: {
    callsData() {
      this.calls = [...this.callsData]
    }
  },
  computed: {
    formattedCallsData() {
      let hasTiketName = ""
      let formattedCallsData = [...this.calls, hasTiketName]
      formattedCallsData.map((data) => {
        if (data.date) {
          data.date = data.date.split('T')[0].split('-').reverse().join('.')
        }
        if (data.hasTiket === true) {
          data.hasTiketName = "Тикет со звонком"
        }
        if (data.hasTiket === false) {
          data.hasTiketName = "Тикет без звонка"
        }
      })
      return formattedCallsData
    },
  },
  methods: {
    editRowData(event) {
      this.$emit('editRow', event)
    },
    async deleteRow(itemId) {
      const accept = confirm('Вы точно хотите удалить строку?')
      if (!accept) return

      const res = await request({
        endpoint: `/api/data/delCallData?dataId=${itemId}`,
        method: 'DELETE',
      });

      if (res.status === 200) {
        this.calls = this.calls.filter(call => call.id !== itemId)
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка удаления',
          timeout: 3000
        })
      }
    }
  }
}
</script>

<style scoped>

.table {
  max-width: 100%;
}

</style>