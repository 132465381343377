<template>
  <div class="container-main">
    <div class="container-inner">
      <v-btn
          class="container-inner__startCallBtn"
          color="primary"
          elevation="5"
          x-large
          @click="modalActive = true"
      >
        Проверенные досье
      </v-btn>

    </div>
    <document-check-table
        :oksm-data="oksmData"
        :document-data="documentData"
        :document-data-loaded="documentDataLoaded"
        :document-params="documentParams"
    />
    <document-check-modal
        v-if="modalActive"
        :document-params="documentParams"
        :oksm-data="oksmData"
        :user-data="userData"
        @closeModal="modalActive = false"
        @finishCheck="finishCheck($event)"
    />
  </div>
</template>

<script>
import request from "@/services/request";
import {mapActions, mapGetters} from "vuex";
import DocumentCheckTable from "@/components/tables/DocumentCheckTable";
import DocumentCheckModal from "@/components/modals/DocumentCheckModal";

export default {
  name: "MainContainerDocumentCheck",
  components: {
    DocumentCheckModal,
    DocumentCheckTable
  },
  props: {
    userData: {
      type: Object,
    },
    oksmData: {
      type: Array,
    },
  },
  data() {
    return {
      modalActive: false,
      documentData: [],
      documentDataLoaded: false,
      documentParams: [
        {title: 'Дата', value: 'date'},
        {title: 'Новое досье', value: 'documentNew'},
        {title: 'Доработка досье', value: 'documentEdited'},
        {title: 'Визовое указание', value: 'visaSend'},
        {title: 'Визовый запрос', value: 'visaQuery'},
        {title: 'Направление на обучение', value: 'educationSend'},
        {title: 'Регистрация направления на обучение', value: 'educationRegister'},
        {title: 'Проверка списков на ДПП', value: 'dppCheckList'},
        {title: 'Отклоненные досье', value: 'documentReject'},
        {title: 'Перевод досье в отказ (2014 - 2021)', value: 'documentCancel21'},
        {title: 'Перевод досье в отказ (2022)', value: 'documentCancel22'},
        {title: 'Отчет по проверке досье в РС', value: 'documentCheckRc'},
        {title: 'Письмо о несоответствии ДПП в РС', value: 'dppFailed'},
        {title: 'Письмо от МОН в РС, ООВО', value: 'letterMonRcOovoId'},
        {title: 'Подготовка справки', value: 'referenceCreated', tableEnabled: true},
        {title: 'Проверка справки', value: 'referenceCheck', tableEnabled: true},
        {title: 'Ответ на письмо гражданина или организации', value: 'answers'},
        {title: 'Презентация', value: 'presentations', tableEnabled: true},
      ]
    }
  },
  mounted() {
    this.getDocumentData()
  },
  watch: {
    modalActive() {
      if (this.modalActive) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflowY = "visible";
        document.body.style.overflowX = "hidden";
      }
    }
  },
  computed: {
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'})
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),

    async getDocumentData() {
      const res = await request({
        endpoint: `/api/dcc/dcca?idUser=${this.userData.id}`,
        method: 'GET',
        token: this.userData.token
      });
      console.log("ALL DOCUMENTS", res)

      this.documentData = res.data
    },
    async finishCheck(event) {
      console.log(event.document)

      const res = await request({
        endpoint: `/api/dcc/dcc?idUser=${this.userData.id}`,
        body: event.document,
        method: 'PUT'
      });

      console.log(res)
      if (res.status === 200 || res.status === 201) {
        if (this.documentData[0].id === event.document.id) {
          this.documentData.shift()
        }
        this.documentData.unshift(event.document)
        this.modalActive = false

      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка отправки данных',
          timeout: 3000
        })
      }
    },
  }
}
</script>

<style scoped>

.container-main {
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 90px 15px 15px;
  overflow: auto;
}

.container-inner {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1.3rem;
  background-color: var(--default-color);
  border-radius: 7px;
  box-shadow: 0 2px 20px 0 rgba(150, 150, 150, 0.1);
}

.container-inner:last-child {
  margin-bottom: 0;
}

.container-inner__admin {
  max-width: 300px;
  position: absolute;
}

.container-inner__startCallBtn {
  max-width: 300px;
  margin: 0 auto;
}

.container-inner__pageInformation {
  color: var(--primary-color);
  font-size: 1.2rem;
}

</style>