<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal__container">
        <div class="modal__btn-close">
          <v-icon
              color="var(--primary-color)"
              size="25"
              @click="$emit('closeModalWithoutSave')"
          >mdi-close
          </v-icon>
        </div>
        <div class="modal__item-title">Изменение данных по звонку</div>

        <v-radio-group v-model="editedData.callType" row>
          <v-radio :value="sprData.callTypes[0].name"
                   class="modal__form-radio"
          >
            <template v-slot:label>
              <div class="modal__form-radio">
                {{ sprData.callTypes[0].name }}
              </div>
            </template>
          </v-radio>
          <v-radio :value="sprData.callTypes[1].name"
                   class="modal__form-radio"
          >
            <template v-slot:label>
              <div class="modal__form-radio">
                {{ sprData.callTypes[1].name }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>

        <vue-tel-input v-model="editedData.phoneNumber"
                       class="modal__form-phoneNumber"
                       @keydown="blurInput($event)"
        ></vue-tel-input>

        <v-autocomplete v-model="editedData.oksm"
                        :items="getSelectionArray(formattedOksmList)"
                        class="modal__form-autocomplete"
                        label="Введите страну"
                        outlined
                        @keydown="blurInput($event)"
        ></v-autocomplete>
        <v-autocomplete v-model="editedData.region"
                        :items="getSelectionArray(sprData.regions)"
                        v-if="showRegions"
                        class="modal__form-autocomplete"
                        label="Введите регион"
                        outlined
                        @keydown="blurInput($event)"
        ></v-autocomplete>


        <v-autocomplete v-model="editedData.applicationCategory"
                        :items="getSelectionArray(sprData.applicationTopics)"
                        class="modal__form-autocomplete"
                        label="Введите укрупнённую тему"
                        outlined
                        @keydown="blurInput($event)"
        ></v-autocomplete>
        <v-autocomplete v-model="editedData.applicationTopic"
                        :items="getTopics"
                        v-if="editedData.applicationCategory "
                        class="modal__form-autocomplete"
                        label="Введите тему"
                        outlined
                        @keydown="blurInput($event)"
        ></v-autocomplete>


        <v-autocomplete v-model="editedData.applicantType"
                        :items="getApplicantType"
                        v-if="editedData.applicationTopic"
                        class="modal__form-autocomplete"
                        label="Введите категорию"
                        outlined
                        @keydown="blurInput($event)"
        ></v-autocomplete>
        <!--              <v-text-field-->
        <!--                  v-model="editedData.applicantTypeAdditionalInfo"-->
        <!--                  :style="!showApplicantAdditionalInfo ? {visibility: 'hidden'} : ''"-->
        <!--                  class="modal__form-textField"-->
        <!--                  outlined-->
        <!--                  placeholder="Дополнительная информация"-->
        <!--              ></v-text-field>-->

        <v-radio-group v-model="editedData.content" row>
          <v-radio
              v-for="(type, index) in sprData.applicationTypes"
              :key="index"
              :value="type"
              class="modal__form-radio"
          >
            <template v-slot:label>
              <div class="modal__form-radio">
                {{ type }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-textarea v-model="editedData.content"
                    class="modal__form-textarea"
                    label="Описание обращения"
                    outlined
                    rows="3"
                    @keydown="blurInput($event)"
        ></v-textarea>

        <v-radio-group v-model="editedData.hasTiket" row>
          <v-radio class="modal__form-radio" :value="0">
            <template v-slot:label>
              <div class="modal__form-radio">
                Не выбрано
              </div>
            </template>
          </v-radio>
          <v-radio class="modal__form-radio" :value="true">
            <template v-slot:label>
              <div class="modal__form-radio">
                Тикет со звонком
              </div>
            </template>
          </v-radio>
          <v-radio class="modal__form-radio" :value="false">
            <template v-slot:label>
              <div class="modal__form-radio">
                Тикет без звонка
              </div>
            </template>
          </v-radio>
        </v-radio-group>

        <v-select
            v-model="editedData.callStatus"
            :items="getSelectionArray(sprData.callStatuses)"
            class="modal__form-select"
            label="Выберите статус"
            outlined
            @keydown="blurInput($event)"
        ></v-select>
        <v-textarea v-model="editedData.description"
                    class="modal__form-textarea"
                    outlined
                    rows="7"
                    @keydown="blurInput($event)"
        ></v-textarea>
        <div class="modal__btn-save">
          <v-btn
              color="var(--primary-color)"
              dark
              elevation="5"
              large
              @click="finishEdit"
          >Сохранить изменения
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import moment from 'moment'

moment.locale('ru')

export default {
  name: "CallModalEdit",
  components: {VueTelInput},
  props: {
    userData: {
      type: Object,
      required: true,
    },
    sprData: {
      type: Object,
      required: true,
    },
    dataToEdit: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      editedData: {},
      applicationTopicsArray: [],
      formattedOksmList: [],
    }
  },
  mounted() {
    this.getModalData()
    this.setOksmList()
  },
  computed: {
    showRegions() {
      return this.editedData.oksm === "РОССИЯ"
    },
    getTopics() {
      const selectedCategory = this.editedData.applicationCategory
      this.sprData.applicationTopics.map(category => {
        if (selectedCategory && category.name === selectedCategory) {
          this.applicationTopicsArray = category.applicationTopics
        }
      })
      return this.getSelectionArray(this.applicationTopicsArray)
    },
    getApplicantType() {
      const selectedTopic = this.editedData.applicationTopic
      let applicantTypesArray = []
      this.applicationTopicsArray.map(topic => {
        if (topic.name === selectedTopic) {
          applicantTypesArray = topic.applicantTypes
        }
      })
      return this.getSelectionArray(applicantTypesArray)
    },
    showApplicantAdditionalInfo() {
      return this.editedData.applicantType === "Ручной ввод "
    }
  },
  methods: {
    getModalData() {
      let editedData = {...this.dataToEdit};
      const getNameById = (sprType, dataToEditValue) => {
        if (editedData[dataToEditValue]) {
          let name;
          this.sprData[sprType].map(obj => {
            if (obj.id === editedData[dataToEditValue].id) {
              name = obj.name
            }
          });
          return name
        }
      };
      if (editedData.hasTiket === null) editedData.hasTiket = 0;
      editedData.date = null;
      editedData.callType = getNameById('callTypes', 'callType');
      editedData.callStatus = getNameById('callStatuses', 'callStatus');
      editedData.oksm = getNameById('oksms', 'oksm');
      editedData.region = getNameById('regions', 'region');

      if (editedData.applicationCategory) {
        editedData.applicationCategory = editedData.applicationCategory.name;

        if (editedData.applicationTopic) {
          editedData.applicationTopic = editedData.applicationTopic.name;

          if (editedData.applicantType) {
            editedData.applicantType = editedData.applicantType.name
          }
        }
      }

      this.editedData = editedData
    },
    setOksmList() {
      const tempArray = this.sprData.oksms.sort(country => {
        if ([9, 16, 34, 81, 113, 219, 228, 232, 244].indexOf(country.id) !== -1) return -1
        else return 1
      })
      this.formattedOksmList = tempArray.sort(country => {
        if ([185].indexOf(country.id) !== -1) return -1
        else return 1
      })
    },
    getSelectionArray(array) {
      let newArray = []
      array.map(item => newArray.push(item.name))
      return newArray
    },
    blurInput(event) {
      if (event.key === 'Escape') {
        event.target.blur()
      }
    },
    finishEdit() {
      const getIdByName = (sprType, callDataValue) => {
        if (this.editedData[callDataValue]) {
          let id
          this.sprData[sprType].map(obj => {
            if (obj.name === this.editedData[callDataValue]) {
              id = obj.id
            }
          })
          return id
        } else return null
      }

      let editedData = {
        id: this.editedData.id,
        date: this.editedData.date,
        hasTiket: this.editedData.hasTiket===0?null:this.editedData.hasTiket,
        phoneNumber: this.editedData.phoneNumber,
        callTypeId: getIdByName('callTypes', 'callType'),
        callLineId: this.editedData.callLine.id,
        operatorId: this.userData.id,
        callStatusId: getIdByName('callStatuses', 'callStatus'),
        oksmId: getIdByName('oksms', 'oksm'),
        applicantTypeId: getIdByName('applicantTypes', 'applicantType'),
        content: this.editedData.content,
        description: this.editedData.description,
      }

      if (editedData.oksmId === 185) {
        editedData.regionId = getIdByName('regions', 'region')
      } else {
        editedData.regionId = null
      }

      if (this.editedData.applicationCategory) {
        this.sprData.applicationTopics.map(category => {

          if (category.name === this.editedData.applicationCategory) {
            editedData.applicationCategoryId = category.id

            if (this.editedData.applicationTopic) {
              category.applicationTopics.map(topic => {
                if (topic.name === this.editedData.applicationTopic) {
                  editedData.applicationTopicId = topic.id
                }
              })
            }

          }
        })
      }

      this.$emit('finishEdit', {
        editedData: editedData
      })
    },
  },

}
</script>

<style scoped>

.modal-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.modal {
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.modal__container {
  width: 60vw;
  max-width: 700px;
  height: 70vh;
  position: relative;
  padding: 30px 50px;
  color: var(--primary-color);
  background-color: var(--default-color);
  border-radius: 7px;
  overflow-y: auto;
}

.modal__btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.modal__item-title {
  margin: 20px 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.modal__btn-save {
  text-align: center;
}

.modal__form-textarea, .modal__form-select, .modal__form-autocomplete {
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  color: var(--primary-color);
}

.modal__form-radio {
  color: var(--primary-color);
  margin-right: 30px;
}

.modal__form-phoneNumber {
  padding: 10px;
  margin-bottom: 30px;
}

.modal__form-phoneNumber:focus-within {
  border: 2px solid var(--primary-color);
  box-shadow: none;
}


</style>
