import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import ru from 'vuetify/src/locale/ru.ts'
import Keycloak from "keycloak-js";
import store from '@/store'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput, {
  dropdownOptions: {showSearchBox: true},
  inputOptions: {autofocus: true, placeholder: 'Введите номер телефона начиная со знака +',},
  mode: 'international', validCharactersOnly: true
});
Vue.use(Vuetify);

Vue.config.productionTip = false
const initOptions = {
  url: 'https://auth.ined.ru/auth',
  realm: 'Ined_users',
  clientId: 'ined_client',
  onLoad: 'login-required'
};

const keycloak = Keycloak(initOptions);
keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
  if (!auth) {
    // console.log('UnAuthorized', auth)
  } else {
    //console.log("Authenticated", auth);
    // store.dispatch('Default/setAuthData', keycloak);
    new Vue({
      store,
      vuetify: new Vuetify(
          {
            lang: {
              current: 'ru',
              locales: {ru},
            },
            icons: {
              iconfont: 'mdi',
            },
            theme: {
              themes: {
                light: {
                  primary: '#00599B',
                  secondary: '#0292e1',
                  accent: '#82B1FF',
                  error: '#ED393E',
                  info: '#618AA7',
                  success: '#29A128',
                  warning: '#D4990A',
                }
              }
            }
          }
      ),
      render: h => h(App, {props: {keycloak: keycloak}}),
    }).$mount('#app');
    //Token Refresh
    setInterval(() => {
      keycloak.updateToken(300).then((refreshed) => {
        if (refreshed) {
          // console.log('Token refreshed' + refreshed);
        } else {
          // console.log('Token not refreshed, valid for '
          //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(() => {
        // console.log('Failed to refresh token');
      });
    }, 15000)

  }
})
    .catch(() => {
      console.log("Authenticated Failed");
      // history.back()
    });