<template id="app">
  <v-app>
    <v-main>
      <CNotificationsFromStore/>
      <main-header :userData="userData" :documentCheck="enableDocumentCheckPage"/>
      <div v-if="isLoading"
           class="loader__container"
      >
        <v-progress-circular
            color="primary"
            size="60"
            indeterminate>
        </v-progress-circular>
      </div>
      <div v-else>
        <main-container-document-check :oksm-data="sprData.oksms" :user-data="userData"
                                       v-if="enableDocumentCheckPage"/>
        <main-container :sprData="sprData" :sprDataLoaded="sprDataLoaded" :userData="userData"
                        v-else
        />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import './assets/styles/main.css'
import CNotificationsFromStore from "./CNotificationsFromStore";
import MainHeader from "@/components/headers/MainHeader";
import MainContainer from "@/components/containers/MainContainer";
import MainContainerDocumentCheck from "@/components/containers/MainContainerDocumentCheck";
import request from "@/services/request";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      userData: {
        id: this.keycloak.idTokenParsed.sub,
        name: this.keycloak.idTokenParsed.name,
        callLine: '',
        isAdmin: false,
        token: this.keycloak.token
      },
      sprData: {
        applicantTypes: [],
        applicationTopics: [],
        callStatuses: [],
        callTypes: [],
        oksms: [],
        regions: [],
        operators: [],
        callLines: [],
        applicationTypes: ['Технический вопрос', 'Методический вопрос'],
        hasTiketTypes: ['Тикет со звонком', 'Тикет без звонка']
      },
      sprDataLoaded: false,
    }
  },
  components: {
    MainContainer,
    MainContainerDocumentCheck,
    MainHeader,
    CNotificationsFromStore
  },
  props: {
    keycloak: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
    this.setAuthData(this.keycloak)
  },
  computed: {
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),

    enableDocumentCheckPage() {
      if (this.keycloak.realmAccess.roles.indexOf('DOCUMENT_CHECK_USER') !== -1) {
        this.getDataForDocumentCheck()
        return true
      } else {
        this.getUserData()
        this.getSprData()
        return false
      }
    }
  },
  methods: {
    ...mapActions('keycloak', {setAuthData: 'setAuthData'}),
    ...mapActions('callLine', {setCallLine: 'setCallLine'}),

    async getDataForDocumentCheck() {
      let oksm = await request({
        endpoint: `/api/dictionary/getOKSM`,
        method: 'GET',
      });
      this.sprData.oksms = oksm.data
      if (oksm.status === 200) {
        this.isLoading = false
      }
    },

    async getUserData() {

      const isAdmin = this.keycloak.realmAccess.roles.indexOf("ADMIN_ROLE")
      if (isAdmin !== -1) {
        this.userData.isAdmin = true
        this.userData.name = this.keycloak.idTokenParsed.given_name
      } else {
        this.userData.isAdmin = false
      }

      const operatorData = await request({
        endpoint: `/api/dictionary/getOperator/${this.userData.id}`,
        method: 'GET',
      });
      if (operatorData.status === 200) {
        this.userData.callLine = operatorData.data.callLine
        console.log(this.userData.callLine)
        await this.setCallLine(localStorage?.callLineId === undefined ? this.userData.callLine : {
          id: localStorage.callLineId,
          name: localStorage.callLineName
        })
      }
    },

    async getSprData() {
      const applicantTypes = await request({
        endpoint: `/api/dictionary/getApplicantType`,
        method: 'GET',
      });
      this.sprData.applicantTypes = applicantTypes.data

      const applicationTopics = await request({
        endpoint: `/api/dictionary/getApplicationCategory`,
        method: 'GET',
      });
      // const applicationTopics = await request({
      //   endpoint: `/api/dictionary/getApplicationTopic`,
      //   method: 'GET',
      // });
      this.sprData.applicationTopics = applicationTopics.data

      const callStatuses = await request({
        endpoint: `/api/dictionary/getCallStatus`,
        method: 'GET',
      });
      this.sprData.callStatuses = callStatuses.data

      const callTypes = await request({
        endpoint: `/api/dictionary/getCallType`,
        method: 'GET',
      });
      this.sprData.callTypes = callTypes.data

      const callLines = await request({
        endpoint: `/api/dictionary/getCallLine`,
        method: 'GET',
      });
      this.sprData.callLines = callLines.data

      const oksm = await request({
        endpoint: `/api/dictionary/getOKSM`,
        method: 'GET',
      });
      this.sprData.oksms = oksm.data

      const regions = await request({
        endpoint: `/api/dictionary/getRegion`,
        method: 'GET',
      });
      this.sprData.regions = regions.data

      const operators = await request({
        endpoint: `/api/dictionary/getOperators`,
        method: 'GET',
      });
      this.sprData.operators = operators.data
      this.sprDataLoaded = true
      this.isLoading = false
    }
  }
}

</script>

<style>
#app {
  background-color: var(--backgroundColor);
}

.loader__container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
