import store from "@/store/index";

export default {
  name: 'Utils',
  authHeader(token) {
    const user = JSON.parse(localStorage.getItem('vuex'));
    if (token || store.getters['keycloak/getAuthData']?.token || user.auth?.accessToken) {
      return {Authorization: `Bearer   ${token || store.getters['keycloak/getAuthData'].token || user.auth.accessToken}`};
    } else {
      return {};
    }
  },
}