export default {
  namespaced: true,
  state: {
    keycloak: null,
  },
  mutations: {
    ['SET_AUTH_DATA'](state, data) {
      state.keycloak = data;
    },
  },
  actions: {
    async setAuthData({commit}, payload) {
      commit('SET_AUTH_DATA', payload);
    },
    async keycloakLogout({commit, state}) {
      if (state.keycloak) {
        state.keycloak.logout();
        commit('SET_AUTH_DATA', null)
      }
    }
  },
  getters: {
    getAuthData(state) {
      return state.keycloak
    },
  }
}