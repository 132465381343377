import axios from 'axios'
import mapper from '@/services/mapper.js'
import utils from "@/utils/utils";

/**
 * [request description]
 * @param {Object} options содержит свойства endpoint, method, body, path, model, headers
 * @return {Object} возвращает promise
 * */
async function request(options) {
  // const {endpoint, method, body, path, model, headers, token} = options;
  // let response;
  // try {
  //   if (method.toLowerCase() === 'get') {
  //     response = await axios.get(endpoint, {headers: {...headers, ...utils.authHeader(token)}});
  //   } else if (method.toLowerCase() === 'post') {
  //     if (headers) {
  //       response = await axios.post(endpoint, body, {headers: {...headers, ...utils.authHeader(token)}});
  //     } else {
  //       response = await axios.post(endpoint, body, {headers: utils.authHeader(token)});
  //     }
  //   } else if (method.toLowerCase() === 'put') {
  //     if (headers) {
  //       response = await axios.put(endpoint, body, {headers: {...headers, ...utils.authHeader(token)}});
  //     } else {
  //       response = await axios.put(endpoint, body, {headers: utils.authHeader(token)});
  //     }
  //   } else if (method.toLowerCase() === 'delete')
  //     response = await axios.delete(endpoint, {headers: {...headers, ...utils.authHeader(token)}});
  //   if (response.status !== 200 && response.status !== 201) {
  //     throw new Error(response.statusText);
  //   }
  const {endpoint, method, body, path, model, headers, withoutToken = false} = options;
  let response;
  try {
    const token = !withoutToken ? utils.authHeader() : '';
    if (method.toLowerCase() === 'get') {
      response = await axios.get(endpoint, {headers: {...headers, ...token  }});
    } else if (method.toLowerCase() === 'post') {
      if (headers) {
        response = await axios.post(endpoint, body, {headers: {...headers, ...token } });
      } else {
        response = await axios.post(endpoint, body, {headers: token});
      }
    }
    else if (method.toLowerCase() === 'put') {
      if (headers) {
        response = await axios.put(endpoint, body, {headers: {...headers, ...token } });
      } else {
        response = await axios.put(endpoint, body, {headers: token});
      }
    } else 	if (method.toLowerCase() === 'delete')
      response = await axios.delete(endpoint, {headers: {...headers, ...token }});

     if (response.status !== 200 && response.status !== 201) {
      const message = response.data.errorMessage ?  response.data.errorMessage : response.statusText;
      throw new FetchError(response, response.data, message, message.status);
    }

    // Если нужно маппить
    if (model) {
      let formatData;
      if (path) {
        formatData = await mapper(response.data[path], model);
      } else {
        formatData = await mapper(response.data, model);
      }
      const counterData = response.data.counter;
      return {data: formatData, counter: counterData};
    } else {
      return response;
    }
  } catch (e) {
    const message = e.data?.errorMessage ?  e.data?.errorMessage : e.statusText;
    throw new FetchError(e, e.data, message);
  }
}
export class FetchError extends Error {
  name = '';

  constructor(response, body, message) {
    super(message);
    this.response = response;
    this.body = body;
  }
}
export default request;