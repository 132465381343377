export default {
    namespaced: true,
    state: {
        callLine: null,
    },
    mutations: {
        ['SET_CALL_LINE'](state, data) {
            state.callLine = data;
            localStorage.callLineId = data.id;
            localStorage.callLineName = data.name;
        },
    },
    actions: {
        async setCallLine({commit}, payload) {
            commit('SET_CALL_LINE', payload);
        },
    },
    getters: {
        getCallLine(state) {
            return state.callLine
        },
    }
}
