<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal__container">
        <div class="closer__container">
          <img class="closer" :src="require(`@/assets/icons/cross.svg`)" @click="close">
        </div>
        <div class="modal__btn-prev">
          <v-icon
              v-if="currentCallStage > 1"
              color="var(--primary-color)"
              size="25"
              @click="goToPrevCallStage">mdi-arrow-left
          </v-icon>
        </div>

        <div class="modal__item">

          <div class="modal__item-title">{{ getFormTitle }}</div>
          <div class="modal__item-form">

            <div v-show="currentCallStage === 1">
              <v-radio-group v-model="modalData.callType" row>
                <v-radio :value="sprData.callTypes[0].name"
                         class="modal__form-radio"
                >
                  <template v-slot:label>
                    <div class="modal__form-radio">
                      {{ sprData.callTypes[0].name }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="sprData.callTypes[1].name"
                         class="modal__form-radio"
                >
                  <template v-slot:label>
                    <div class="modal__form-radio">
                      {{ sprData.callTypes[1].name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>

            <div v-show="currentCallStage === 2">
              <vue-tel-input v-model="modalData.phoneNumber"
                             class="modal__form-phoneNumber"
              ></vue-tel-input>
            </div>

            <div v-show="currentCallStage === 3">
              <v-autocomplete v-model="modalData.oksm"
                              :items="getSelectionArray(formattedOksmList)"
                              class="modal__form-autocomplete"
                              label="Введите страну"
                              outlined
              ></v-autocomplete>
              <v-autocomplete v-model="modalData.region"
                              :items="getSelectionArray(sprData.regions)"
                              :style="!showRegions ? {visibility: 'hidden'} : ''"
                              class="modal__form-autocomplete"
                              label="Введите регион"
                              outlined
              ></v-autocomplete>
            </div>

            <div v-show="currentCallStage === 4">
              <v-autocomplete v-model="modalData.applicationCategory"
                              :items="getSelectionArray(sprData.applicationTopics)"
                              class="modal__form-autocomplete"
                              label="Введите укрупнённую тему"
                              outlined
              ></v-autocomplete>
              <v-autocomplete v-model="modalData.applicationTopic"
                              :items="getTopics"
                              :style="!modalData.applicationCategory ? {visibility: 'hidden'} : ''"
                              class="modal__form-autocomplete"
                              label="Введите тему"
                              outlined
              ></v-autocomplete>
            </div>

            <div v-show="currentCallStage === 5">
              <v-autocomplete v-model="modalData.applicantType"
                              :items="getApplicantType"
                              class="modal__form-autocomplete"
                              label="Введите категорию"
                              outlined
              ></v-autocomplete>
              <!--              <v-text-field-->
              <!--                  v-model="modalData.applicantTypeAdditionalInfo"-->
              <!--                  :style="!showApplicantAdditionalInfo ? {visibility: 'hidden'} : ''"-->
              <!--                  class="modal__form-textField"-->
              <!--                  outlined-->
              <!--                  placeholder="Дополнительная информация"-->
              <!--              ></v-text-field>-->
            </div>

            <div v-show="currentCallStage === 6">
              <v-radio-group v-model="modalData.content" row>
                <v-radio
                    class="modal__form-radio"
                    :value="type"
                    v-for="(type, index) in sprData.applicationTypes"
                    :key="index"
                >
                  <template v-slot:label>
                    <div class="modal__form-radio">
                      {{ type }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <v-textarea v-model="modalData.content"
                          class="modal__form-textarea"
                          label="Описание обращения"
                          outlined
                          rows="3"
              ></v-textarea>
            </div>

            <div v-show="currentCallStage === 7">
              <v-radio-group v-model="modalData.hasTiket" row>
                <v-radio class="modal__form-radio" :value="0">
                  <template v-slot:label>
                    <div class="modal__form-radio">
                      Не выбрано
                    </div>
                  </template>
                </v-radio>
                <v-radio class="modal__form-radio" :value="true">
                  <template v-slot:label>
                    <div class="modal__form-radio">
                      Тикет со звонком
                    </div>
                  </template>
                </v-radio>
                <v-radio class="modal__form-radio" :value="false">
                  <template v-slot:label>
                    <div class="modal__form-radio">
                      Тикет без звонка
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>

            <div v-show="currentCallStage === 8">
              <v-select
                  v-model="modalData.callStatus"
                  :items="getSelectionArray(sprData.callStatuses)"
                  class="modal__form-select"
                  label="Выберите статус"
                  outlined
              ></v-select>
            </div>

            <div v-show="currentCallStage === 9">
              <v-textarea v-model="modalData.description"
                          class="modal__form-textarea"
                          outlined
                          rows="7"
              ></v-textarea>
            </div>

          </div>
        </div>

        <v-btn v-if="currentCallStage < 8"
               class="modal__item-button"
               color="var(--primary-color)"
               dark
               elevation="5"
               large
               @click="goToNextCallStage"
        >Далее
        </v-btn>
        <v-btn v-else
               class="modal__item-button"
               color="var(--primary-color)"
               dark
               elevation="5"
               large
               @click="finishCall"
        >Завершить
        </v-btn>

      </div>
    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import moment from 'moment'
import {mapGetters} from "vuex";
moment.locale('ru')

export default {
  name: "CallModal",
  components: {VueTelInput},
  props: {
    userData: {
      type: Object,
      required: true,
    },
    sprData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentCallStage: 1,
      modalData: {
        id: null,
        date: '',
        phoneNumber: null,
        callType: 'Входящий',
        hasTiket: 0,
        callStatus: null,
        oksm: null,
        region: null,
        applicationCategory: null,
        applicationTopic: null,
        applicantType: null,
        applicantTypeAdditionalInfo: null,
        content: null,
        description: null,
      },
      applicationTopicsArray: [],
      formattedOksmList: [],
    }
  },
  mounted() {
    this.getCallStartDate()
    this.setOksmList()
  },
  computed: {
    ...mapGetters('callLine', {getCallLine: 'getCallLine'}),
    getFormTitle() {
      let currentTitle;
      switch (this.currentCallStage) {
        case 1:
          currentTitle = 'Тип звонка'
          break;
        case 2:
          currentTitle = 'Номер телефона'
          break;
        case 3:
          currentTitle = 'Страна и регион'
          break;
        case 4:
          currentTitle = 'Тема обращения'
          break;
        case 5:
          currentTitle = 'Категория обратившегося'
          break;
        case 6:
          currentTitle = 'Описание обращения'
          break;
        case 7:
          currentTitle = 'Тикет'
          break;
        case 8:
          currentTitle = 'Статус звонка'
          break;
        case 9:
          currentTitle = 'Примечание'
          break;
      }
      return currentTitle
    },
    showRegions() {
      return this.modalData.oksm === "РОССИЯ"
    },
    getTopics() {
      const selectedCategory = this.modalData.applicationCategory
      this.sprData.applicationTopics.map(category => {
        if (selectedCategory && category.name === selectedCategory) {
          this.applicationTopicsArray = category.applicationTopics
        }
      })
      return this.getSelectionArray(this.applicationTopicsArray)
    },
    getApplicantType() {
      const selectedTopic = this.modalData.applicationTopic
      let applicantTypesArray = []
      this.applicationTopicsArray.map(topic => {
        if (topic.name === selectedTopic) {
          applicantTypesArray = topic.applicantTypes
        }
      })
      return this.getSelectionArray(applicantTypesArray)
    },
    showApplicantAdditionalInfo() {
      return this.modalData.applicantType === "Ручной ввод "
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getCallStartDate() {
      const callStartTime = new Date()
      this.modalData.date = `${moment(callStartTime).format('L')} ${moment(callStartTime).format('LTS')}`
    },
    setOksmList() {
      const tempArray = this.sprData.oksms.sort(country => {
        if ([9, 16, 34, 81, 113, 219, 228, 232, 244].indexOf(country.id) !== -1) return -1
        else return 1
      })
      this.formattedOksmList = tempArray.sort(country => {
        if ([185].indexOf(country.id) !== -1) return -1
        else return 1
      })
    },
    goToNextCallStage() {
      if (this.currentCallStage < 8) {
        this.currentCallStage++
      } else {
        this.currentCallStage = 1
      }
    },
    goToPrevCallStage() {
      if (this.currentCallStage > 1) {
        this.currentCallStage--
      }
    },
    getSelectionArray(array) {
      let newArray = []
      array.map(item => newArray.push(item.name))
      return newArray
    },
    finishCall() {
      const getIdByName = (sprType, callDataValue) => {
        if (this.modalData[callDataValue]) {
          let id
          this.sprData[sprType].map(obj => {
            if (obj.name === this.modalData[callDataValue]) {
              id = obj.id
            }
          })
          return id
        } else return null
      }

      let finalCallData = {
        id: this.modalData.id,
        date: this.modalData.date,
        hasTiket: this.modalData.hasTiket===0?null:this.modalData.hasTiket,
        phoneNumber: this.modalData.phoneNumber,
        callTypeId: getIdByName('callTypes', 'callType'),
        callLineId: this.getCallLine.id,
        operatorId: this.userData.id,
        callStatusId: getIdByName('callStatuses', 'callStatus'),
        oksmId: getIdByName('oksms', 'oksm'),
        regionId: getIdByName('regions', 'region'),
        applicantTypeId: getIdByName('applicantTypes', 'applicantType'),
        content: this.modalData.content,
        description: this.modalData.description,
      }

      if (this.modalData.applicationCategory) {
        this.sprData.applicationTopics.map(category => {

          if (category.name === this.modalData.applicationCategory) {
            finalCallData.applicationCategoryId = category.id

            if (this.modalData.applicationTopic) {
              category.applicationTopics.map(topic => {
                if (topic.name === this.modalData.applicationTopic) {
                  finalCallData.applicationTopicId = topic.id
                }
              })
            }

          }
        })
      }

      this.modalData = {
        date: '',
        phoneNumber: null,
        callType: 'Входящий',
        callStatus: null,
        oksm: null,
        region: null,
        applicationCategory: null,
        applicationTopic: null,
        applicantType: null,
        applicantTypeAdditionalInfo: null,
        content: null,
        description: null,
      }

      this.$emit('finishCall', {
        callData: finalCallData
      })
    },
  },

}
</script>

<style scoped>

.modal-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.modal {
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.modal__container {
  width: 60vw;
  max-width: 700px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 50px;
  color: var(--primary-color);
  background-color: var(--default-color);
  border-radius: 7px;
}

.modal__btn-prev {
  align-self: flex-start;
  cursor: pointer;
}

.modal__item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.modal__item-title {
  margin: 20px 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.modal__item-form {
  width: 100%;
}

.modal__item-button {
  align-self: center;
}

.modal__form-textarea, .modal__form-select, .modal__form-autocomplete, .modal__form-textField {
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  color: var(--primary-color);
}

.modal__form-radio {
  color: var(--primary-color);
  /*margin-right: 30px;*/
}

.modal__form-phoneNumber {
  padding: 10px;
}

.modal__form-phoneNumber:focus-within {
  border: 2px solid var(--primary-color);
  box-shadow: none;
}

.closer__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.closer {
  cursor: pointer;
  position: absolute;
}

</style>
