<template>
  <div class="header">

    <div class="header-left">
      <div class="header__logo">
        <img src="../../assets/icons/logo.svg" class="header__logo-img" alt="Логотип">
        <span class="header__logo-text">Интеробразование</span>
      </div>
      <h1 class="header__title" v-if="documentCheck">Кабинет управления досье</h1>
      <h1 class="header__title" v-else>Кабинет оператора колл-центра</h1>
    </div>

    <div class="header-right">
      <div class="header__user-name">{{ userData.name }}</div>
      <div class="header__logout">
        <v-icon :size="35" color="primary" @click="logout">mdi-logout</v-icon>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "HeaderMain",
  props: {
    userData: {
      type: Object,
      required: true
    },
    documentCheck: {
      type: Boolean,
    }
  },
  methods: {
    ...mapActions('keycloak', {keycloakLogout: 'keycloakLogout'}),

    logout() {
      this.keycloakLogout()
    },
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  min-height: 64px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: var(--default-color);
}

.header-left, .header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-right {
  margin-right: 45px;
}

.header__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: var(--primary-color);
}

.header__logo-img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.header__logo-text {
  color: var(--default-color);
  font-size: 1.1rem;
  text-transform: uppercase;
}

.header__title {
  padding: 0 35px;
  color: var(--color-blue-dark);
  font-size: 1.1rem;
  font-weight: 600;
  text-align: left;
}

.header__user-name {
  color: var(--color-blue-dark);
  text-align: right;
}

.header__logout {
  margin-left: 30px;
  cursor: pointer;
}
</style>