<template>
  <div class="container-main">
    <div class="container-inner">
      <v-btn
          v-if="userData.isAdmin"
          :loading="!sprDataLoaded"
          class="container-inner__admin-data"
          color="success"
          elevation="5"
          x-large
          @click="openModal"
      >
        Выгрузки
      </v-btn>
      <v-btn
              v-if="userData.isAdmin"
              :loading="!sprDataLoaded"
              class="container-inner__admin"
              color="success"
              elevation="5"
              x-large
              @click="openModal2"
      >
        Операторы
      </v-btn>
      <v-btn
          :loading="!sprDataLoaded"
          class="container-inner__startCallBtn"
          color="primary"
          elevation="5"
          x-large
          @click="startCall"
      >
        Начать звонок
      </v-btn>
      <v-select
          class="container-inner__selectLine"
          label="Линия оператора"
          v-model="selectLine"
          :items="operatorLines"
          item-text="name"
          return-object
          @change="setCallLineStore"
      ></v-select>
    </div>

    <v-progress-circular
        color="primary"
        size="60"
        style="margin-top: 50px;"
        v-if="!callsDataLoaded"
        indeterminate
    ></v-progress-circular>
    <div v-else>
      <call-data-table
          :callsData="callsData"
          @editRow="editRow($event)"
      />
      <call-modal
          v-if="modalActive"
          :sprData="sprData"
          :userData="userData"
          @finishCall="finishCall($event)"
          @close="modalActive = false"
      />
      <call-modal-edit
          v-if="modalEditActive"
          :sprData="sprData"
          :userData="userData"
          :data-to-edit="dataToEdit"
          @closeModalWithoutSave="closeModalWithoutSave"
          @finishEdit="finishEdit($event)"
      />
      <ModalExportDialog :sprCallLine="sprData.callLines" v-if="modalOpen" @close="modalOpen = false"/>
      <ModalOperatorList :spr="sprData.operators" v-if="modalOpen2" @close="modalOpen2 = false"></ModalOperatorList>
    </div>

  </div>
</template>

<script>
import CallModal from "@/components/modals/CallModal";
import CallDataTable from "@/components/tables/CallDataTable";
import request from "@/services/request";
import {mapActions, mapGetters} from "vuex";
import CallModalEdit from "../modals/CallModalEdit";
import ModalExportDialog from "../modals/ModalExportDialog";
import ModalOperatorList from "../modals/ModalOperatorList";

export default {
  name: "MainContainer",
  components: {
    ModalOperatorList,
    ModalExportDialog,
    CallModalEdit,
    CallModal,
    CallDataTable,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    sprData: {
      type: Object,
      required: true,
    },
    sprDataLoaded: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      modalOpen: false,
      modalOpen2: false,
      modalActive: false,
      modalEditActive: false,
      callsData: [],
      callsDataLoaded: false,
      dataToEdit: {},
      selectLine: null,
      operatorLines: [
        { id: 1, name: "Абитуриент" },
        { id: 2, name: "Вуз" },
        { id: 3, name: "Поступление иностранных граждан" }
      ]
    }
  },
  created() {
    this.selectLine = this.getCallLine
  },
  mounted() {
    this.getCallCenterData()
  },
  computed: {
    ...mapGetters('callLine', {getCallLine: 'getCallLine'})
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('callLine', {setCallLine: 'setCallLine'}),

    setCallLineStore(event) {
      this.setCallLine(event);
    },

    async getCallCenterData() {
      let callsData
      await this.userData.id
      if (this.userData.isAdmin) {
        callsData = await request({
          endpoint: `/api/data/getCallData`,
          method: 'GET',
        });
      } else {
        callsData = await request({
          endpoint: `/api/data/getCallDataOperator?operatorId=${this.userData.id}`,
          method: 'GET',
        });
      }

      this.callsData = callsData.data
      this.callsDataLoaded = true
    },

    openModal() {
      this.modalOpen = true
    },

    openModal2() {
      this.modalOpen2 = true
    },

    startCall() {
      this.modalActive = true
    },

    editRow(event) {
      const editedRowId = this.callsData.findIndex(data => data.id === event.id)
      this.dataToEdit = this.callsData[editedRowId]
      this.modalEditActive = true
    },

    closeModalWithoutSave() {
      let accept = confirm('Закрыть окно без сохранения?')
      if (accept) {
        this.modalEditActive = false
      }
    },

    async finishCall(data) {
      const res = await request({
        endpoint: `/api/data/setCallData`,
        method: 'POST',
        body: data.callData
      });

      if (res.status === 200 || res.status === 201) {
        this.callsData.unshift(res.data)
        this.modalActive = false
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка отправки данных',
          timeout: 3000
        })
      }
    },

    async finishEdit(data) {
      const res = await request({
        endpoint: `/api/data/setCallData`,
        method: 'POST',
        body: data.editedData
      });

      if (res.status === 200 || res.status === 201) {
        const editedCallIndex = this.callsData.findIndex(call => call.id === res.data.id)
        if (editedCallIndex !== -1) {
          this.callsData.splice(editedCallIndex, 1, res.data)
          this.modalEditActive = false
        }
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка отправки данных',
          timeout: 3000
        })
      }
    }
  },

}

</script>

<style scoped>

.container-main {
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 90px 15px 15px;
  overflow: auto;
}

.container-inner {
  width: 100%;
  display: flex;
  /*justify-content: center;*/
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1.3rem;
  background-color: var(--default-color);
  border-radius: 7px;
  box-shadow: 0 2px 20px 0 rgba(150, 150, 150, 0.1);
}

.container-inner:last-child {
  margin-bottom: 0;
}

.container-inner__admin {
  max-width: 300px;
  position: absolute;
  left: 400px;
}

.container-inner__admin-data {
  max-width: 300px;
  position: absolute;
}

.container-inner__startCallBtn {
  max-width: 300px;
  margin: 0 auto;
}

.container-inner__selectLine {
  width: 600px;
  position: absolute;
  right: 40px;
}

.container-inner__pageInformation {
  color: var(--primary-color);
  font-size: 1.2rem;
}

</style>
