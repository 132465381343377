<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal__container">
                <div class="closer__container">
                    <img class="closer" :src="require(`@/assets/icons/cross.svg`)" @click="close">
                </div>
                <div class="modal__item-title">Список операторов</div>
                <div class="separator"></div>
                <v-data-table
                        :headers="headers"
                        :items="spr"
                        class="elevation-1"
                        item-key="id"
                        loading-text="Загрузка..."
                        no-data-text="Данные отсутствуют"
                ></v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModalOperatorList",
        props: {
            spr: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                headers: [
                    {text: 'Место оператора', width: 140, value: 'description'},
                    {text: 'ФИО', width: 160, value: 'name'},
                    {text: 'Линия', value: 'callLine.name'},
                    {text: 'Последний вход', value: 'lastEntrance'}
                ]
            }
        },
        methods: {
            close() {
                this.$emit('close')
            },
        }
    }
</script>

<style scoped>
    .modal-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 10;
    }

    .modal {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
    }

    .modal__container {
        width: 110vw;
        max-width: 1100px;
        height: 70vh;
        position: relative;
        padding: 30px 50px;
        color: var(--primary-color);
        background-color: var(--default-color);
        border-radius: 7px;
        overflow-y: auto;
        text-align: center;
    }

    .modal__section {
        display: flex;
        flex-direction: column;
        margin: 16px 0;
    }

    .modal__item-title {
        margin-bottom: 20px;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .container-inner__admin {
        width: auto;
        margin: 8px;
    }

    .closer__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }

    .closer {
        cursor: pointer;
        position: absolute;
    }

    .separator {
        width: 100%;
        border-bottom: 1px solid #CCC;
    }
</style>