<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal__container">
                <div class="closer__container">
                    <img class="closer" :src="require(`@/assets/icons/cross.svg`)" @click="close">
                </div>
                <div class="modal__item-title">Выгрузки</div>
                <div class="separator"></div>
                <div class="modal__section">
                    <date-picker class="container-inner__admin" :format="datePickerOptions.format"
                                 :lang="datePickerOptions.lang"
                                 :value-type="datePickerOptions.valueType"
                                 placeholder="Выберите даты"
                                 range
                                 v-model="newObj"
                    ></date-picker>
                    <v-btn
                            @click="operatorsCalls2"
                            class="container-inner__admin"
                            color="success"
                            elevation="5"
                            x-large
                    >
                        Выгрузка звонков за период
                    </v-btn>
                </div>
                <div class="separator"></div>
                <div class="modal__section">
                    <v-btn
                            @click="operatorsCalls"
                            class="container-inner__admin"
                            color="success"
                            elevation="5"
                            x-large
                    >
                        Выгрузка всех звонков
                    </v-btn>
                </div>
                <div class="separator"></div>
                <div class="modal__section">
                    <date-picker class="container-inner__admin" :format="datePickerOptions.format"
                                 :lang="datePickerOptions.lang"
                                 :value-type="datePickerOptions.valueType"
                                 placeholder="Выберите даты"
                                 range
                                 v-model="selectDates"
                    ></date-picker>
                    <v-select v-model="selectLine"
                        :items="sprCallLine"
                        item-text="name"
                        item-value="id"
                        return-object
                        class="modal__form-select"
                        label="Выберите линию"></v-select>
                    <v-btn
                            @click="operatorsCalls3"
                            class="container-inner__admin"
                            color="success"
                            elevation="5"
                            x-large
                    >
                        Выгрузка звонков за период по линии
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import axios from "axios";
    import utils from "@/utils/utils";
    import moment from 'moment'

    moment.locale('ru')
    export default {
        name: "ModalExportDialog",
        components: {DatePicker},
        props: {
            sprCallLine: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                newObj: null,
                selectDates: null,
                selectLine: null,
                datePickerOptions: {
                    lang: {
                        formatLocale: {
                            firstDayOfWeek: 1,
                        },
                        monthBeforeYear: false,
                    },
                    format: 'YYYY-MM-DD',
                    valueType: 'timestamp',
                    datePicker: true
                }
            }
        },
        computed: {
            getCallLines() {
                return this.getSelectionArray(this.sprCallLine)
            }
        },
        methods: {
            getSelectionArray(array) {
                let newArray = []
                array.map(item => newArray.push(item.name))
                return newArray
            },

            close() {
                this.$emit('close')
            },

            async operatorsCalls() {
                const responseAxios = await axios.get(`/api/export/operatorsCalls`, {
                    headers: {...utils.authHeader()},
                    responseType: 'blob',
                })
                this.saveByteArray("callsData.xlsx", responseAxios.data)
            },

            async operatorsCalls2() {
                this.newObj[0] = moment(this.newObj[0]).format('YYYY-MM-DD')
                this.newObj[1] = moment(this.newObj[1]).format('YYYY-MM-DD')

                const responseAxios = await axios.get(`/api/export/operatorsCalls2?dateStart=${this.newObj[0]}&dateEnd=${this.newObj[1]}`, {
                    headers: {...utils.authHeader()},
                    responseType: 'blob',

                })
                this.saveByteArray("callsData.xlsx", responseAxios.data)
            },

            async operatorsCalls3() {
              this.selectDates[0] = moment(this.selectDates[0]).format('YYYY-MM-DD')
              this.selectDates[1] = moment(this.selectDates[1]).format('YYYY-MM-DD')

              const responseAxios = await axios.get(`/api/export/operatorsCalls3?dateStart=${this.selectDates[0]}&dateEnd=${this.selectDates[1]}&callLine=${this.selectLine.id}`, {
                headers: {...utils.authHeader()},
                responseType: 'blob',

              })
              this.saveByteArray("callsDataLine.xlsx", responseAxios.data)
            },

            saveByteArray(reportName, byte) {
                let blob = new Blob([byte], {type: "application/xlsx"});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = reportName;
                link.click();
            },
        }

    }
</script>

<style scoped>
    .modal__form-textarea, .modal__form-select, .modal__form-autocomplete, .modal__form-textField {
        width: 100%;
        margin: 10px 0;
        padding: 5px 10px;
        color: var(--primary-color);
    }

    .modal-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 10;
    }

    .modal {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
    }

    .modal__container {
        width: 60vw;
        max-width: 700px;
        height: 70vh;
        position: relative;
        padding: 30px 50px;
        color: var(--primary-color);
        background-color: var(--default-color);
        border-radius: 7px;
        overflow-y: auto;
        text-align: center;
    }

    .modal__section {
        display: flex;
        flex-direction: column;
        margin: 16px 0;
    }

    .modal__item-title {
        margin-bottom: 20px;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .container-inner__admin {
        width: auto;
        margin: 8px;
    }

    .closer__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }

    .closer {
        cursor: pointer;
        position: absolute;
    }

    .separator {
        width: 100%;
        border-bottom: 1px solid #CCC;
    }
</style>
