import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";
import keycloak from "@/store/keycloak";
import cNotifications from "./cNotifications";
import callLine from "@/store/callLine";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth,
  }),
});
export default new Vuex.Store({
  modules: {
    keycloak: keycloak,
    callLine: callLine,
    notifications: cNotifications,
  },
  plugins: [vuexLocal.plugin]
})
