<template>
  <div class="table">
    <div style="text-align: center; padding: 30px 0" v-if="!tableData.length">
      <v-progress-circular
          color="primary"
          size="50"
          indeterminate
      ></v-progress-circular>
    </div>
    <v-simple-table v-else>
      <thead>
      <tr>
        <th v-for="(param, index) in documentParams" :key="index" class="text-left"
            style="min-width: 150px">
          {{ param.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(dataObject, index) in tableData"
          :key="index"
      >
        <td>{{ dataObject.date.split('T')[0].split('-').reverse().join('.') }}</td>
        <td>{{ dataObject.documentNew }}</td>
        <td>{{ dataObject.documentEdited }}</td>
        <td>{{ dataObject.visaSend }}</td>
        <td>{{ dataObject.visaQuery }}</td>
        <td>{{ dataObject.educationSend }}</td>
        <td>{{ dataObject.educationRegister }}</td>
        <td>{{ dataObject.dppCheckList }}</td>
        <td>{{ dataObject.documentReject }}</td>
        <td>{{ dataObject.documentCancel21 }}</td>
        <td>{{ dataObject.documentCancel22 }}</td>
        <td>{{ dataObject.documentCheckRc }}</td>
        <td>{{ dataObject.dppFailed }}</td>
        <td>{{ dataObject.letterMonRcOovoId }}</td>
        <td>
          <div v-for="(ref, index) in dataObject.referenceCreated"
               :key="index"
               style="width: 300px !important; font-size: 12px"
          >
            <span v-if="ref.number">{{ ref.number }}, </span>
            <span v-if="ref.oksmName">{{ ref.oksmName }}, </span>
            <span v-if="ref.subject">{{ ref.subject }}</span>
          </div>
        </td>
        <td>
          <div v-for="(ref, index) in dataObject.referenceCheck"
               :key="index"
               style="width: 300px !important; font-size: 12px"
          >
            <span v-if="ref.number">{{ ref.number }}, </span>
            <span v-if="ref.oksmName">{{ ref.oksmName }}, </span>
            <span v-if="ref.subject">{{ ref.subject }}</span>
          </div>
        </td>
        <td>{{ dataObject.answers }}</td>
        <td>
          <div v-for="(ref, index) in dataObject.presentations"
               :key="index"
               style="width: 300px !important; font-size: 12px"
          >
            <span v-if="ref.number">{{ ref.number }}, </span>
            <span v-if="ref.subject">{{ ref.subject }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "DocumentCheckTable",
  props: {
    documentData: {
      type: Array,
      required: true,
    },
    oksmData: {
      type: Array,
      required: true,
    },
    documentParams: {
      type: Array,
      required: true,
    }
  },
  computed: {
    tableData() {
      let data = [...this.documentData]
      data.map(item => {
        if (item.referenceCreated) {
          item.referenceCreated.map(ref => {
            if (ref.oksmId) {
              this.oksmData.map(oksm => {
                if (oksm.id === ref.oksmId) {
                  ref.oksmName = oksm.name
                }
              })
            }
          })
        }
        if (item.referenceCheck) {
          item.referenceCheck.map(ref => {
            if (ref.oksmId) {
              this.oksmData.map(oksm => {
                if (oksm.id === ref.oksmId) {
                  ref.oksmName = oksm.name
                }
              })
            }
          })
        }
      })

      return data
    }
  }
}
</script>

<style scoped>

.table {
  width: 100%;
}

</style>