<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div v-if="!editedDocumentLoaded">
        <v-progress-circular color="primary"></v-progress-circular>
      </div>
      <div class="modal__container" v-else>
        <div class="modal__name">{{ userData.name }}</div>
        <div class="modal__field-date">
          {{ editedDocument.date.split('T')[0].split('-').reverse().join('.') }}
        </div>
        <div
            v-for="(param, index) in documentParams"
            :key="index"
            class="modal__field">
          <div v-if="!param.tableEnabled && param.value !== 'date'" class="modal__field-input">
            <v-text-field
                v-model="editedDocument[param.value]"
                :disabled="noData"
                :label="param.title"
                type="number"
                dense
                hide-details="auto"
                outlined
            ></v-text-field>
          </div>
          <div v-if="param.tableEnabled" class="modal__table">
            <div class="modal__table-label">
              <div class="modal__table-label-title">{{ param.title }}</div>
              <v-btn
                  class="modal__table-btn-add"
                  color="primary"
                  fab
                  outlined
                  x-small
                  @click="addRow(param.value, editedDocument.id)"
              >
                <v-icon title="Добавить строку">mdi-plus</v-icon>
              </v-btn>
            </div>
            <div class="modal__table-header">
              <div class="modal__table-title">Количество</div>
              <div v-if="param.value !== 'presentations'" class="modal__table-title">Страна</div>
              <div class="modal__table-title">Тематика</div>
            </div>
            <div xlass="modal__table-rows">
              <div v-for="(object, index) in editedDocument[param.value]"
                   :key="index"
                   class="modal__table-row"
              >
                <div class="modal__table-cell-text">
                  <v-text-field
                      v-model="object.number"
                      :disabled="noData"
                      type="number"
                      @change="changeRow(param.value, editedDocument.id, object.id, index)"
                  />
                </div>
                <div
                    v-if="param.value !== 'presentations'"
                    class="modal__table-cell-select"
                >
                  <v-select
                      :items="getOksmList"
                      v-model="object.oksmName"
                      :disabled="noData"
                      @change="changeRow(param.value, editedDocument.id, object.id, index)"
                  >
                  </v-select>
                </div>
                <div class="modal__table-cell-text">
                  <v-text-field
                      v-model="object.subject"
                      :disabled="noData"
                      @change="changeRow(param.value, editedDocument.id, object.id, index)"
                  />
                </div>
                <div class="modal__table-btn-delete" @click="deleteRow(param.value, object.id)">
                  <v-icon size="20" title="Удалить строку">mdi-close</v-icon>
                </div>
              </div>
            </div>

          </div>
        </div>
        <v-switch
            v-model="noData"
            label="Сотрудник болеет или в отпуске"
        ></v-switch>
        <div class="modal__button-save" @click="finishCheck">
          <v-btn
              color="var(--primary-color)"
              dark
              elevation="5"
              large
          >Сохранить
          </v-btn>
        </div>
        <div class="modal__close" @click="$emit('closeModal')">
          <v-icon color="var(--primary-color)" size="35">mdi-close</v-icon>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import request from "@/services/request";

export default {
  name: "DocumentCheckModal",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    documentParams: {
      type: Array,
      required: true,
    },
    oksmData: {
      type: Array,
    },
  },
  data() {
    return {
      editedDocument: {},
      editedDocumentLoaded: false,
      noData: false,
    }
  },
  mounted() {
    this.getDocumentData()
  },
  computed: {
    getOksmList() {
      let oksmNames = []
      this.oksmData.map(oksm => {
        oksmNames.push(oksm.name)
      })
      return oksmNames
    }
  },
  methods: {
    async getDocumentData() {
      const res = await request({
        endpoint: `/api/dcc/dcc?idUser=${this.userData.id}`,
        method: 'GET',
        token: this.userData.token
      });

      this.editedDocument = res.data

      if (this.editedDocument.referenceCreated) {
        this.editedDocument.referenceCreated.map(ref => {
          if (ref.oksmId) {
            this.oksmData.map(oksm => {
              if (oksm.id === ref.oksmId) {
                ref.oksmName = oksm.name
              }
            })
          }
        })
      }
      if (this.editedDocument.referenceCheck) {
        this.editedDocument.referenceCheck.map(ref => {
          if (ref.oksmId) {
            this.oksmData.map(oksm => {
              if (oksm.id === ref.oksmId) {
                ref.oksmName = oksm.name
              }
            })
          }
        })
      }

      console.log("ONE DOCUMENT", this.editedDocument)
      this.editedDocumentLoaded = true
    },
    async addRow(objectKey, docId) {
      let apiPath
      switch (objectKey) {
        case 'presentations':
          apiPath = 'pr'
          break;
        case 'referenceCheck':
          apiPath = 'rch'
          break;
        case 'referenceCreated':
          apiPath = 'rcr'
          break
      }

      const newObj = {
        number: '',
        subject: '',
      }

      let res = await request({
        endpoint: `/api/dcc/${apiPath}?docId=${docId}`,
        method: 'PUT',
        body: {}
      });
      newObj.id = res.data.id
      if (!this.editedDocument[objectKey]) {
        this.editedDocument[objectKey] = []
      }
      this.editedDocument[objectKey].push(newObj)
    },
    async changeRow(objectKey, docId, id, rowIndex) {
      let apiPath
      switch (objectKey) {
        case 'presentations':
          apiPath = 'pr'
          break;
        case 'referenceCheck': {
          apiPath = 'rch'
          let refCheckOksmName = this.editedDocument.referenceCheck[rowIndex].oksmName
          this.oksmData.map(oksm => {
            if (oksm.name === refCheckOksmName) {
              this.editedDocument.referenceCheck[rowIndex].oksmId = oksm.id
            }
          })
        }
          break;
        case
        'referenceCreated'
        : {
          apiPath = 'rcr';
          let refCreatedOksmName = this.editedDocument.referenceCreated[rowIndex].oksmName
          this.oksmData.map(oksm => {
            if (oksm.name === refCreatedOksmName) {
              this.editedDocument.referenceCreated[rowIndex].oksmId = oksm.id
            }
          })
        }
          break
      }
      console.log('SENT DATA', this.editedDocument[objectKey][rowIndex])

      let res = await request({
        endpoint: `/api/dcc/${apiPath}?docId=${docId}`,
        method: 'PUT',
        body: this.editedDocument[objectKey][rowIndex]
      });
      console.log(res)

      if (!id) {
        this.editedDocument[objectKey][rowIndex].id = res.data.id
      }
    }
    ,
    async deleteRow(objectKey, id) {
      let apiPath
      switch (objectKey) {
        case 'presentations':
          apiPath = 'pr'
          break;
        case 'referenceCheck':
          apiPath = 'rch'
          break;
        case 'referenceCreated':
          apiPath = 'rcr'
          break
      }

      await request({
        endpoint: `/api/dcc/${apiPath}?id=${id}`,
        method: 'DELETE',
      });
      this.editedDocument[objectKey] = this.editedDocument[objectKey].filter(obj => obj.id !== id)
    }
    ,
    finishCheck() {
      console.log(this.editedDocument)
      this.oksmData.map(oksm => {
        if (this.editedDocument.referenceCreated) {
          this.editedDocument.referenceCreated.map(ref => {
            if (ref.oksmId && oksm.name === ref.oksmId.name) {
              ref.oksmId = oksm
            }
          })
        }
        if (this.editedDocument.referenceCheck) {
          this.editedDocument.referenceCheck.map(ref => {
            if (ref.oksmId && oksm.name === ref.oksmId.name) {
              ref.oksmId = oksm
            }
          })
        }
      })
      this.$emit('finishCheck', {
        document: this.editedDocument
      })
    }
  }
}
</script>

<style scoped>

.modal-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.modal {
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.modal__container {
  width: 80vw;
  max-width: 1100px;
  height: 70vh;
  position: relative;
  padding: 30px 60px;
  color: var(--primary-color);
  background-color: var(--default-color);
  border-radius: 7px;
  overflow-y: auto;
}

.modal__name {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.modal__field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.modal__field-date {
  text-align: center;
}

.modal__field-input {
  width: 100%;
}

.modal__table {
  width: 100%;
}

.modal__table-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.modal__table-row {
  min-width: calc(100% / 2);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -27px
}

.modal__table-row:first-child {
  margin-top: 0;
}

.modal__table-header {
  min-width: calc(100% / 2);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal__table-title {
  width: calc(100% / 2);
  padding: 10px 0;
  text-align: center;
  border: 1px solid #777777;
  border-left: none;
}

.modal__table-title:nth-child(1) {
  width: calc(100% / 3);
  border-left: 1px solid #777777;
}

.modal__table-cell-text {
  width: calc(100% / 2);
  flex-grow: 0;
}

.modal__table-cell-text:first-child {
  width: calc(100% / 3);
  /*border-right: 1px solid #777777;*/
}

.modal__table-cell-select {
  width: calc(100% / 2);
  flex-grow: 0;
  /*border-right: 1px solid #777777;*/
}

.modal__table-btn-delete {
  position: absolute;
  left: -25px;
  top: 15px;
  cursor: pointer;
}

.modal__close {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.modal__button-save {
  text-align: center;
}

</style>