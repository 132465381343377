<template>
  <div class="c-notifications"
       :class="[cssClass.style ? 'c-notifications_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notifications' : '']">
    <transition-group tag="div" name="c-notifications_animate" class="c-notifications__list"
                      :class="[cssClass.style ? 'c-notifications__list_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notifications__list' : '']">
      <div class="c-notification"
           :class="[cssClass.style ? 'c-notification_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification' : '', 'c-notification_' + message.status]"
           v-for="message in get_notifications_messages" :key="message.id">
        <v-icon
            :color="message.status"
            :class="['c-notification__text_' + message.status]">
          {{ choseIcon(message.status) }}
        </v-icon>
        <p class="c-notification__text"
           :class="[cssClass.style ? 'c-notification__text_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification__text' : '', 'c-notification__text_' + message.status]">
          {{ message.message }}</p>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "notifications",
  props: {
    messages: {
      type: Array,
      default: () => {
        return []
      }
    },
    cssClass: {
      type: Object,
      default: function () {
        return {position: '', style: ''};
      },
    },
  },
  methods: {
    choseIcon(status) {
      switch (status) {
        case 'error':
          return 'mdi-close-octagon-outline'
        case 'warning':
          return 'mdi-alert-outline'
        case 'success':
          return 'mdi-checkbox-marked-circle-outline '
        default:
          return 'mdi-message-text'
      }
    }
  },
  computed: {
    ...mapGetters('notifications', {get_notifications_messages: 'get_notifications_messages'})
  },
}
</script>

<style>
.c-notifications {
  position: fixed;
  top: 150px;
  right: 10px;
  z-index: 1200;
}

.c-notifications__list {
  display: flex;
  flex-direction: column-reverse;
}

.c-notification {
  min-width: 300px;
  max-width: 350px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--light-color-background);
  border: 1px solid var(--danger-color);
  border-radius: 17px;
}

.c-notification__text {
  margin-bottom: 0 !important;
  margin-left: 5px;
  font-size: 1.1rem;
  color: var(--light-color);
  text-align: left;
}

.c-notification__status {
  width: 30px;
  margin-right: 10px;
}

.c-notifications_animate-enter {
  transform: translateX(120px);
  opacity: 0;
}

.c-notifications_animate-enter-active {
  transition: all .6s ease;
}

.c-notifications_animate-enter-to {
  opacity: 1;
}

.c-notifications_animate-leave {
  height: 50px;
  opacity: 1;
}

.c-notifications_animate-leave-active {
  transition: transform .6s ease, opacity .6s, height .2s;
}

.c-notifications_animate-leave-to {
  height: 0;
  transform: translateX(120px);
  opacity: 0;
}

.c-notifications_animate-move {
  transition: transform .6s ease;
}

.c-notification_error {
  background-color: var(--danger-color-background)
}

.c-notification_warning {
  background-color: #fff3cd;
}

.c-notification_success {
  background-color: var(--success-color-background)
}

.c-notification__text_error {
  color: var(--danger-color)
}

.c-notification__text_warning {
  color: var(--warning-color)
}

.c-notification__text_success {
  color: var(--success-color)
}

</style>